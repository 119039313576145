const useFetch = async (url) => {
  let response = await fetch( url, {
    method: 'GET',
    headers: new Headers({
      'Accept': 'application/json',
      'Authorization': 'Basic '+btoa('relevate:ilovetech!'),
      'Content-Type': 'application/json'
    }),
  });

  const content = await response.json();

  return content;
}

export default useFetch;
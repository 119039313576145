import Login from './components/Login';
import EnrollmentsTable from './components/EnrollmentsTable';
import EditEnrollment from './components/EditEnrollment';
import ResetPassword from './components/ResetPassword';
import get from './components/Fetch'
import React, { useMemo, useEffect, useState } from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom'
import { Link, useNavigate } from "react-router-dom";
import config from "react-global-configuration";

function App() {

  const [data, setData] = useState([]);
  const [user, setUser] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [error, setError] = useState('');
  const [totalrecords, setTotalrecords] = useState(0);
  
  let rowsperpage = 10;
  let currentpage = 0;
  let totalpages = Math.ceil(totalrecords/rowsperpage); 

  const navigate = useNavigate();

  // keyword=test&sortby=repname&sortorder=asc&offset=0&limit=23
  useEffect(() => {
    if (loggedIn) {
      const apiUrl = config.get('apiUrl') + "index.php";
      const url = new URL(apiUrl);
      const params = new URLSearchParams();

      params.append('offset', offset);
      params.append('limit', 10); 
      
      if(keyword !== '') {
        params.append('keyword', keyword);
      }
      if(sortBy !== '') {
        params.append('sortby', sortBy);
      }
      if(sortOrder !== '') {
        params.append('sortorder', sortOrder);
      }
      url.search = params.toString();

      getTotalRecords();

      get(url.toString()).then(data => {
        setData(data);
        console.log(data);
      }).catch(err => console.log(err));
    }
  }, [user, offset, loggedIn, keyword, sortBy, sortOrder]);

  const getUser = async (email, pwd) => {

    const creds = {
      username: email,
      password: (pwd)
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'accept': 'application/json', 'Authorization': 'Basic '+btoa('relevate:ilovetech!') },
      body: JSON.stringify(creds)
    };

    fetch(config.get('loginUrl'), requestOptions)
      .then(response => response.json())
      .then(async response => {
        console.log(response);
        // debugger;

        if (response.username){
          setLoggedIn(true);
          setUser(response.username);
          localStorage.setItem('user', response.username)
          navigate('/');
        } else if (!response.authorized){
          setError(response.error);
        } else {
          setError('Something went wrong')
        }

      });
  }

  useEffect(() => {
    const loggedInUser = localStorage.getItem('user');
    if (loggedInUser) {
      const foundUser = loggedInUser;
      setUser(foundUser);
      setLoggedIn(true);
    } else {
      setUser('');
      setLoggedIn(false);
    }
  }, []);

  const handleLogout = () => {
    setUser('');
    setLoggedIn(false);
    localStorage.clear();
  }

  const getTotalRecords = () => {
    const requestOptions = {
      headers: { 'Content-Type': 'application/json', 'accept': 'application/json', 'Authorization': 'Basic '+btoa('relevate:ilovetech!') }
    };
    fetch(config.get('apiUrl') + "count.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
        setTotalrecords(result);
      });
  }

  const postEnrollment = (data) => {
    let furl = config.get('apiUrl');
    console.log(data);
    /*fetch(furl, {
    method: 'POST',
    body: JSON.stringify({
     data
    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    }
    })
    .then(function(response){ 
    return response.json()})
    .then(function(data){
      console.log(data)
    }); */
  }

  const updateEditedRecord = (fields) => {
    let id = fields['AssignID'];
    console.log(data);
    console.log(id);
    let dcopy = [...data];
    let dd = dcopy.filter(obj => {
      return obj.AssignID === id;//id
    })[0];
    Object.assign(dd, fields);
    console.log(dd);
    setData(dcopy);
    console.log(dcopy);
  }

  const getDataByKeyword = () => {

  }

  const updateOffset = (o) => {
    setOffset(o);
  }
  const updateKeyword = (o) => {
    setKeyword(o);
  }
  const updateSortOrder = (o) => {
    setSortOrder(o);
  }
  const updateSortBy = (o) => {
    setSortBy(o);
  }

  const updateError = (e) => {
    setError(e);
  }
  const tableData = useMemo(()=> data, [])

  /** @type import('@tanstack/react-table').ColumnDef<any>*/
  const columns = [
    {
      header: 'Job Number',
      accessorKey: 'AssignID'
    },
    {
      header: 'Rep Name',
      accessorKey: 'repname'
    },
    {
      header: 'Shoot Date',
      accessorKey: 'shootdate'
    },
    {
      header: 'Enroll Date',
      accessorKey: 'enrolldate'
    },
    {
      header: 'Brand',
      accessorKey: 'therapeuticArea'
    },
    {
      header: 'HCP',
      accessorKey: 'physname'
    },
    {
      header: 'Program Code',
      accessorKey: 'webcode'
    },
    {
      header: 'Main Enrollment',
      accessorKey: 'practicename'
    },
    {
      header: 'Rep',
      accessorKey: 'repname'
    },
    {
      header: 'Videographer',
      accessorKey: 'shootername'
    },
    {
      header: 'Status',
      accessorKey: 'AssignStatus'
    },
    {
      header: 'Details',
      accessorKey: 'enrollnotes'
    },
    {
      header: 'Parent ID',
      accessorKey: 'parent_id'
    },

  ]

  return (
    <div className="App">
      {!loggedIn && 
      <Routes>
        <Route path='/' element={<Login getUser={getUser} error={error} updateError={updateError}/>}/>
        <Route path='/reset' element={<ResetPassword/>}/>
      </Routes>
      }
      {loggedIn &&
      <Routes>
        <Route path='/' element={
        <EnrollmentsTable 
          getDataByKeyword={getDataByKeyword} 
          totalrecords={totalrecords} 
          data={data} columns={columns} handleLogout={handleLogout}
          updateOffset={updateOffset} offset={offset}
          updateKeyword={updateKeyword} keyword={keyword} 
          updateSortBy={updateSortBy} sortBy={sortBy} 
          updateSortOrder={updateSortOrder} sortOrder={sortOrder}/>
          
        }/>
        <Route path='/edit' element={<EditEnrollment postEnrollment={postEnrollment} updateEditedRecord={updateEditedRecord}/>}/>
      </Routes>
      }

    </div>
  );
}

export default App;

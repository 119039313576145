import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom'
import config from "react-global-configuration";

config.set(
  {
    apiUrl: process.env.REACT_APP_API_ENDPOINT,
    loginUrl: process.env.REACT_APP_LOGIN_ENDPOINT,
    videographerUrl: process.env.REACT_APP_VIDEOGRAPHER_ENDPOINT
  },
  { freeze: false }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState, useEffect, useMemo } from 'react';
import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel , getSortedRowModel, getToggleSortingHandler, getFilteredRowModel} from '@tanstack/react-table';
import { Link } from "react-router-dom";

const EnrollmentsTable = ({data, columns, offset, updateOffset, totalrecords, updateKeyword, keyword, updateSortBy, sortBy, updateSortOrder, sortOrder, handleLogout}) => {
  const [sorting, setSorting] = useState([])
  const [filtering, setFiltering] = useState("")

  const table = useReactTable({
    data, 
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting: sorting,
      globalFilter: filtering
    }, 
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering
  });
  // console.log(`ROWS: ${JSON.stringify(table.getRowModel().rows,null, 2)}`);

  const nextPage = () => {
    updateOffset(offset + 10)
  }

  const prevPage = () => {
    if(offset > 0){
      updateOffset(offset - 10)
    }
  }

  const sort = (val) => {
    updateSortBy(val);
    updateSortOrder(
      sortOrder === "asc" ? "desc" : "asc"
    );
  }

  useEffect(() =>{
    //console.log(data);
    console.log(totalrecords);
  }, [data])

  return(
    <div className='enrollment__wrapper'>

      <div className="logout button" onClick={handleLogout}>
        <i className="fas fa-sign-out-alt"></i> Logout
      </div>

      <div className='enrollments__container'>
  
        <input className='search' type="text" value={keyword} onChange={(e) => updateKeyword(e.target.value)} placeholder='SEARCH'/>
  
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup, hi) => (
              <tr key={'headerGroup' + hi}>
                {headerGroup.headers.map((header, i) => (
                  <th key={'header' + i} onClick={(e) => sort(header.column.columnDef.accessorKey)}>
                    <div>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      <img src="./images/arrows.png" alt="" />
                    </div>
                  </th>
                ))}
                <th>
                    
                </th>
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, i) => (
              <tr key={'row' + i}>
                {row.getVisibleCells().map((cell, ci) => (
                  <td key={'cell' + ci}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
                <td>
                  <Link to='edit' state={row.original}><button className='button edit'><i className="far fa-edit"></i> Edit</button></Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
  
        <div className='btn-container'>
          <button className={'button ' + ((offset > 0) ? '' : 'inactive')}  onClick={() => prevPage()}><i className="fas fa-angle-left"></i> Prev</button>
          <span className="btn-container__text">Page {Math.ceil((offset/10) + 1)} of {Math.ceil((totalrecords/10))}</span>
          <button className={'button ' + ((Math.ceil((offset/10) + 1) < Math.ceil((totalrecords/10))) ? '' : 'inactive')}  onClick={() => nextPage()}>Next <i className="fas fa-angle-right"></i></button>
        </div>
      </div>
    </div>
  )

};

export default EnrollmentsTable;

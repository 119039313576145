import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import config from "react-global-configuration";

const Login = (props) => {
  const [forgotPass, setForgotPass] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [incorrectLogin, setIncorrectLogin] = useState(false);
  const [incorrectUser, setIncorrectUser] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");
  const [forgotEmailSent, setForgotEmailSent] = useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
      if (!forgotPass) {
          setUserInput(document.getElementById('userInput').value);
          setPasswordInput(document.getElementById('passwordInput').value);
      }
  }, []);

  const handleInput = (e) => {
      setUserInput(e.target.value);
      props.updateError('');
      setIncorrectLogin(false);
      setIncorrectUser(false);
  }

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {

      props.getUser(userInput, passwordInput);
    }
  }

  const getTemplate = (postie, templateName, sendMail) => {
    let regex = new RegExp(/{{([a-zA-Z0-9.]+)}}/g);
    fetch('templates/' + templateName + '.html')
      .then((r)=>r.text())
      .then(text=> {
        let result = text.replace(regex, (x)=>{
            let key = (x.replace(/[{}]/g, ""));
            return postie[key];
        })

        sendMail(result, postie);
      })
  }

  const sendMail = (template, userobj) => {
    let mailObject = {};
    mailObject.recip = document.getElementById('forgotInput').value;
    mailObject.subject = "Reset your MyDoc Admin password";
    mailObject.message = template;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'accept': 'application/json', 'Authorization': 'Basic '+btoa('relevate:ilovetech!') },
      body: JSON.stringify(mailObject)
    };
    
    fetch(config.get('apiUrl') + "mail.php", requestOptions)
        .then(response => response.text())
        .then(text => {
            // console.log(text);
            if (text == 'success') {
                //setShowModal(true);
                setForgotEmailSent(true);
            } else {
                //setShowErrorModal(true);
                setForgotEmailSent(true);
            }
        })
        .catch(err => {
            console.log(err);
            //setShowErrorModal(true);
        });
  }


  const forgotPassword = () => {
    setForgotPass(true);
  }

  const handlePassword = (e) => {
    setPasswordInput(e.target.value);
    props.updateError('');
    setIncorrectLogin(false);
    setIncorrectUser(false);
  }

  const handleForgotPasswordClick = () => {
    let postie = {}
    postie.user = document.getElementById('forgotInput').value; //fix this
    postie.url = window.location.href;
    getTemplate(postie, 'reset-password', sendMail);
  }

  const goHome = () => {
    setForgotPass(false);
    setForgotEmailSent(false);
  }

  const logUserIn = () => {
    props.getUser(userInput, passwordInput);
  }

  return (
      <div className='login__wrapper'>
        <div className="login__container">
  
              {!forgotPass ?
                <div>
                  <h3>MyDoc Program Admin</h3>
                  <input id="userInput" placeholder='Email Address' value={userInput} type="email" onChange={handleInput}  className="login__input text-input"/>
                  <input id="passwordInput" placeholder='Password' value={passwordInput} onKeyPress={handleInputKeyPress} onChange={handlePassword} type="password" className="login__input text-input"/>
                  <button className="button" type="submit" onClick={logUserIn} disabled={userInput.trim().length === 0 || passwordInput.trim().length === 0} >Log in</button>
                  {/*<div className="login__password-link" onClick={forgotPassword}><p>Forgot Password?</p></div>
                  {props.error != "" ? <div className="login__login-warning">{props.error}</div> : <div></div>}*/}
                </div>
              :
              !forgotEmailSent ?
                <div className='forgotPassword'>
                  <h3 className="login__heading heading--main">Forgot Password?</h3>
                  <p>Enter your email address below. Your password will be reset and an email will be sent with instructions on how to create a new one.</p>
                  <input id="forgotInput" placeholder='Email Address' onChange={handlePassword} type="text" className="login__input text-input"/>
                  <button className="login__button button" onClick={handleForgotPasswordClick}>Reset Password</button>
                </div>
              :
                <div>
                  <h3 className="login__heading heading--main">Forgot Password?</h3>
  
                  <p>Please check your inbox for the reset email.</p>
                  <button className="login__button button" onClick={goHome}>Log In</button>
                </div>
              }
        </div>
      </div>
  );
}

export default Login;
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select';
import config from "react-global-configuration";
import ReorderList from './ReorderList';

const EditEnrollment = ({data, updateEditedRecord}) => {
  const location = useLocation();
  const [fields, setFields] = useState(location.state);
  const [originalFields, setoriginalFields] = useState(location.state);
  const [shooter, setShooter] = useState(fields.parent_id ? 'Photographer' : 'Videographer');
  const [vidOptions, setVidOptions] = useState([]);
  const [vidLabels, setVidLabels] = useState([]);
  const [selectedVid, setSelectedVid] = useState({});
  const [currentStatus, setCurrentStatus] = useState('');
  const [updateEnabled, setUpdateEnabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const navigate = useNavigate();
  const statuses = [
    {value: "pending", label: "pending"}, 
    {value: "confirmed", label: "confirmed"}, 
    {value: "filmed", label: "filmed"}, 
    {value: "cancelled", label: "cancelled"}, 
    {value: "postponed", label: "postponed"}
  ];
  const updatedFields = {};


  useEffect(() =>{
    Object.keys(originalFields).forEach((key) => {
      if (originalFields[key] !== fields[key]){
        updatedFields[key] = fields[key];
      }
    });

    requiredCheck();
  }, [fields]);

  const closeModal = () => {
    setShowModal(false);
  }

  const handleVidChange = obj => {
    setSelectedVid(vidOptions.filter((i) => i.vID === obj.value)[0]);
  }

  const handleStatusChange = (e) =>{
    setCurrentStatus(e.value);
    let temp = {...fields};
    temp['AssignStatus'] = e.value;
    //temp[]
    setFields({
      ...temp
    });
  }

  const handleChange = (evt) => {
    //console.log('handleChange');
    const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    let temp = {...fields};
    //console.log(temp);
    temp[evt.target.getAttribute('db-name')] = value;
    //temp[]
    setFields({
      ...temp
    });
  }

  const requiredCheck = () => {
    //console.log('requiredCheck');
    let inputs = Array.from(document.querySelectorAll("[required]"));
    //console.log(inputs);
    let blanks = 0;
    var mailFormat =  /\S+@\S+\.\S+/;
    var validZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    var hourPattern = /^(0?[0-9]|1[0-9]|2[0-3])$/;
    var minutePattern = /(?<!\S)(?:[0-9]|[0-5]\d|60)(?!\S)/;
    
    inputs.map((e, i) => {
      blanks += e.value == "" ? 1 : 0;

      if (
        (e.type === "email" && !e.value.match(mailFormat)) || 
        (e.id === "practicezip" && !e.value.match(validZip)) ||
        (e.id === "shootzip" && !e.value.match(validZip)) || 
        (e.id === "shoottimehrs" && !e.value.match(hourPattern)) || 
        (e.id === "shoottimemins" && !e.value.match(minutePattern))
      ){
        e.classList.add('error');
        setErrorMessage('Highlighted fields are incorrect!');
        setShowError(true);
      } else if (e.value == ""){
        e.classList.add('error');
        setErrorMessage('Highlighted fields must be filled out!');
        setShowError(true);
      } else{
        e.classList.remove('error');
      }
      
      // blanks = blanks + e.value
    });

    blanks > 0 ? setUpdateEnabled(false) : setUpdateEnabled(true);
    //console.log(Object.keys(shootDateSelect).length);
    // blanks += shootDateSelect == undefined ? 1 : 0;
    // document.getElementById("enrollButton").disabled = blanks > 0;
  };

  const updateRecord = async (fields, original) => {
    //console.log('updateRecord ');
    //console.log(fields);
    //console.log('show selectedVid');
    //console.log(selectedVid);

    for (const key in fields) {
      if (fields.hasOwnProperty(key)) { //remove null values
        if (fields[key] == null) {
          delete fields[key];
        }
      }
    }

    for (const key in selectedVid) {
      if (selectedVid.hasOwnProperty(key)) { //remove null values
        if (selectedVid[key] == null) {
          delete selectedVid[key];
        }
      }
    }

    fields.shooterID = selectedVid.vID;
    fields.shootername = selectedVid.Name;
    fields.companyname = selectedVid.CompanyName;
    fields.shooterphone = selectedVid.PrimPhone;
    fields.shootercell = selectedVid.SecPhone;
    fields.shooteralt = selectedVid.videographer_alter;
    fields.shooteremail = selectedVid.Email;
   
    const requestOptions = {
      method: 'PUT',
      headers: new Headers({
      'Accept': 'application/json',
      'Authorization': 'Basic '+btoa('relevate:ilovetech!'),
      'Content-Type': 'application/json'
    }),
      body: JSON.stringify(fields)
    };    

    fetch(config.get('apiUrl') + "index.php", requestOptions)
      .then(response => response)
      .then(async response => {
        console.log(response);
        
        updateEditedRecord(fields);
        setShowModal(true);

      });
  }

  useEffect(() =>{

    const getVideographerInfo = () => {

      setCurrentStatus(fields['AssignStatus']);
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'accept': 'application/json', 'Authorization': 'Basic '+btoa('relevate:ilovetech!') }
      };

      fetch(config.get('videographerUrl'), requestOptions)
        .then(response => response.json())
        .then(async response => {
          const labels = response.map(mapResponseToValuesAndLabels);
          setVidOptions(response);
          setVidLabels(labels);
          setSelectedVid(response.filter((i) => i.vID === fields.shooterID)[0] ?? {});
        });
    }

    getVideographerInfo();
    
  }, []);

  const mapResponseToValuesAndLabels = (data) => ({
    value: data.vID,
    label: data.vID + ' - ' + data.Name,
  });
  
  return (

    <div className='enrollment__wrapper'>
    <button type='button' className='button back' onClick={() => navigate('/')}><i className="fas fa-arrow-left"></i> Back</button>
    {showModal &&
      <div className="modal">
        <div className="modal__content">
          <div className="modal__close" onClick={closeModal}>close</div>
          <p className="modal__big-p">Success!</p>
          <p>This record has been successfully updated 👍</p>
        </div>
      </div>
    }
      <div className='enrollments__container'>
        <h1>Edit { fields.parent_id ? 'Sub Enrollment' : 'Main Enrollment' } | Id: {fields.AssignID}</h1>

        <div className="headline">

          <div className="title">
            <p>Date of Filming</p>
            <p>Job No.</p>
            <p>Brand</p>
            <p>HCP</p>
            <p>Rep</p>
          </div>
          
          <div className="info">
            <p>{originalFields.shootdate}</p>
            <p>{originalFields.AssignID}</p>
            <p>{originalFields.therapeuticArea}</p>
            <p>{originalFields.physname}</p>
            <p>{originalFields.repname}</p>
          </div>

        </div>


        <form className='edit__enroll__form'>

          <div className='group'>

            <div>
              <p>Status</p>
              <Select
                value={{value: currentStatus, label: currentStatus}}
                options={statuses}
                onChange={(e) => handleStatusChange(e)}
                className='videographer__select'

              />
            </div>

            <p className='header'>Representative Information</p>

            <div>
              <p>Name</p>
                
              <input onChange={handleChange} type="text" db-name='repname' disabled value={fields.repname}/>
            </div>

            <div>
              <p>Territory</p>
              <input onChange={handleChange} type="text" db-name='repterritory' disabled value={fields.repterritory}/>
            </div>

            <div>
              <p>Cell</p>
              <input onChange={handleChange} type="text" db-name='repcell' disabled value={fields.repcell}/>
            </div>

            <div>
              <p>Email</p>
              <input onChange={handleChange} type="email" db-name='repemail' disabled value={fields.repemail} />
            </div>

            <div>
              <p>Institution</p>
              <input onChange={handleChange} type='text' db-name='institution' disabled value={fields.institution}/>
            </div>
  
            <p className='header'>Practice Information</p>
            <div>
              <p>Practice Name*</p>

              <input onChange={handleChange} type="text" db-name='practicename' value={fields.practicename} required  placeholder="Enter the practice name" />
            </div>

            <div>
              <p>Physician Name*</p>
              <input onChange={handleChange} type="text" db-name='physname' value={fields.physname} required placeholder="Enter the physician name" />
            </div>

            <div>
              <p>Street*</p>
              <input onChange={handleChange} type="text" db-name='practiceaddress' value={fields.practiceaddress} required placeholder="Enter the street address" />
            </div>

            <div>
              <p>City*</p>
              <input onChange={handleChange} type="text" db-name='practicecity' value={fields.practicecity} required placeholder="Enter the city name" />
            </div>

            <div>
              <p>State*</p>
              <input onChange={handleChange} type="text" db-name='practicestate' value={fields.practicestate} required placeholder="Enter the state abbreviation" />
            </div>

            <div>
              <p>Zip Code*</p>
              <input onChange={handleChange} type="text" db-name='practicezip' id='practicezip' value={fields.practicezip} required placeholder="Enter a 5 digit zip code" />
            </div>
  
            <p className='header'>Key Contact Person</p>
            <div>
              <p>Name*</p>
              <input onChange={handleChange} type="text" db-name='contactname' value={fields.contactname} required placeholder="Enter contact name" />
            </div>

            <div>
              <p>Email*</p>
              <input onChange={handleChange} type="email" db-name='contactemail' value={fields.contactemail} required  placeholder="example@mail.com" />
            </div>

            <div>
              <p>Phone*</p>
              <input onChange={handleChange} type="text" db-name='contactphone' value={fields.practicephone} required placeholder="ex:123-456-7890" />
            </div>

          </div>

          <div className='group'>
            
            <p className='header'>Main Participant</p>
            <div>
              <p>Name*</p>
                
              <input onChange={handleChange} type="text" db-name='physname' value={fields.physname} required placeholder="Enter main participant name" />
            </div>

            <div>
              <p>Credentials*</p>
              <input onChange={handleChange} type="text" db-name='partcred' value={fields.partcred} required placeholder="Enter main participant credentials" />
            </div>
  
            <p className='header'>Additional Participants</p>
            <div>
              <p>Name</p>
              
              <input onChange={handleChange} type="text" db-name='part2name' value={fields.part2name} placeholder="Enter additional participant name" />
            </div>

            <div>
              <p>Credentials</p>
              <input onChange={handleChange} type="text" db-name='part2cred' value={fields.part2cred} placeholder="Enter additional participant credentials" />
            </div>

            <div>
              <p>Name</p>
              <input onChange={handleChange} type="text" db-name='part3name' value={fields.part3name} placeholder="Enter additional participant name" />
            </div>

            <div>
              <p>Credentials</p>
              <input onChange={handleChange} type="text" db-name='part3cred' value={fields.part3cred} placeholder="Enter additional participant credentials" />
            </div>

            <div>
              <p>Name</p>
              <input onChange={handleChange} type="text" db-name='part4name' value={fields.part4name} placeholder="Enter additional participant name" />
            </div>

            <div>
              <p>Credentials</p>
              <input onChange={handleChange} type="text" db-name='part4cred' value={fields.part4cred} placeholder="Enter additional participant credentials" />
            </div>

          </div>

          {!fields.parent_id &&
            (<div className='group'>

              <p className='header'>Shooting Information</p>
              <div className='time_fields'>
                <p>Shoot time*</p>
                  
                <input placeholder='hour' onChange={handleChange} type="number" db-name='shoottimehrs' id="shoottimehrs" value={fields.shoottimehrs} className='shootTimeInput' required/>
                <input placeholder='minutes' onChange={handleChange} type="number" db-name='shoottimemins' id="shoottimemins" value={fields.shoottimemins} className='shootTimeInput' required/>
                <select name="ampm" db-name="ampm" value={fields.ampm} onChange={handleChange}>
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>

              <div>
                <p>Shoot date*</p>
                <input onChange={handleChange} type="date" db-name='shootdate' value={fields.shootdate} required placeholder="Choose a shoot date" />
              </div>

              <div>
                <p>Street</p>
                <input onChange={handleChange} type="text" db-name='shootstreet' value={fields.shootstreet} placeholder="Enter the shoot address" />
              </div>

              <div>
                <p>City</p>
                <input onChange={handleChange} type="text" db-name='shootcity' value={fields.shootcity} placeholder="Enter the shoot city" />
              </div>

              <div>
                <p>State</p>
                <input onChange={handleChange} type="text" db-name='shootstate' value={fields.shootstate} placeholder="Enter the shoot state" />
              </div>

              <div>
                <p>Zip Code</p>
                <input onChange={handleChange} type="text" db-name='shootzip' id='shootzip' value={fields.shootzip} placeholder="Enter the shoot zip code" />
              </div>

              <div>
                <p>Shoot Phone</p>
                {/* Shooter phone or shooter cell???  shootphone*/}
                <input onChange={handleChange} type="text" db-name='shootphone' value={fields.shootphone} placeholder="Enter the shoot phone number" />
              </div>

              <p className='header'>Select { shooter }</p>

              <div>

              <Select
                value={vidLabels.filter((i) => i.value === selectedVid.vID)[0]}
                onChange={handleVidChange}
                options={vidLabels}
                className='videographer__select'
              />

              </div>

              <div>
                <p>{shooter} name</p>
                <input onChange={handleVidChange} type="text" db-name='vid_name' name='vid_name' disabled value={selectedVid.Name}/>
              </div>

              <div>
                <p>Company name</p>
                <input onChange={handleVidChange} type="text" db-name='vid_company' name='vid_company' disabled value={selectedVid.CompanyName}/>
              </div>

              <div>
                <p>{shooter} phone</p>
                <input onChange={handleVidChange} type="text" db-name='vid_phone' name='vid_phone' disabled value={selectedVid.PrimPhone}/>
              </div>

              <div>
                <p>{shooter} cell</p>
                <input onChange={handleVidChange} type="text" db-name='vid_cell' name='vid_cell' disabled  />
              </div>

              <div>
                <p>{shooter} alt</p>
                <input onChange={handleVidChange} type="text" db-name='vid_alt_phone' name='vid_alt_phone' disabled value={selectedVid.SecPhone}/>
              </div>

              <div>
                <p>{shooter} email</p>
                <input onChange={handleVidChange} type="email" db-name='vid_email' name='vid_email' disabled value={selectedVid.Email}/>
              </div>
          
            </div>)
          }
          
          <div className="action__buttons">
            {/* <p className='errorMessage'>The highlighted fields are required!</p> */}
            {showError ? (<p className='errorMessage'>{errorMessage}</p>) : null}
            <button type='button' className='button update' onClick={() => updateRecord(fields, selectedVid, originalFields)} disabled={!updateEnabled}>Update</button>
            <button type='button' className='button cancel' onClick={() => navigate('/')}><i className="fas fa-times-circle"></i> Cancel</button>
          </div>

          <div className="reorder_information">
            <ReorderList items={originalFields.reorders} />
          </div>
          
  
        </form>
      </div>
    </div>
  )
}

export default EditEnrollment;
import React from 'react';

const TableRow = ({ key, item, index }) => {
  return (
    <tr key={key}>
      <td>#{item.id}</td>
      <td>{item.assignment_id}</td>
      <td>{item.has_changes ? 'Yes' : 'No'}</td>
      <td>{item.practice_name}</td>
      <td>{item.practice_email}</td>
      <td>{item.practice_phone}</td>
      <td>{item.practice_website}</td>
      <td>{item.hcp_names_titles}</td>
    </tr>
  );
};

const ReorderList = ({ items }) => {

  const assignmentIdPresent = items[0].assignment_id;

  const renderRows = () => {
    return items.map((item, index) => <TableRow key={index} item={item} index={index} />);
  };

  return (

    <>
    <h2>REORDERS</h2>
      {
      assignmentIdPresent !== null ? (
      <>
        <table>
          <thead>
            <tr>
              <th><div>Reorder</div></th>
              <th><div>Assignment ID</div></th>
              <th><div>Practice Changes</div></th>
              <th><div>Practice Name</div></th>
              <th><div>Practice Email</div></th>
              <th><div>Practice Phone</div></th>
              <th><div>Practice Website</div></th>
              <th><div>Titles</div></th>
            </tr>
          </thead>
          <tbody>{renderRows()}</tbody>
        </table>
      </>
      ) : (
        <div className='reorder_noentry'>
          <p>No reorders present</p>
        </div>
      )
      }
    </>
  );
}

export default ReorderList;
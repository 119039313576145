import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import bcrypt from 'bcryptjs';

const ResetPassword = (props) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordSaved, setPasswordSaved] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);

    let [params] = useSearchParams();
    let userName = params.get('user');

    const handleLoginClick = () => {
        if (password === confirmPassword) {
            bcrypt.genSalt(5, function (err, salt) {
                bcrypt.hash(password, salt, function (err, hash) {
                    fetchie(hash);
                });
            });
        } else {
            setPasswordsMatch(false);
        }
    }

    const fetchie = (hash) => {
        let postie = {
            password: hash,
        };

        fetch(props.apiPath + 'reset.php?user=' + userName + '&hash=' + hash, {
            method: 'PUT',
            body: JSON.stringify(postie),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
        })
            .then((response) => response.text())
            .then((text) => {
                console.log(text);
                setPasswordSaved(true);
            })
            .catch((err) => console.log(err));
    };

    return (
        <div className="login__wrapper">
            <div className="login__container">
                <div>
                    <h3>Reset Password</h3>
                    {!passwordSaved ? (
                        <div className="forgotPassword">
                            <p>Password</p>
                            <input
                                id="passwordInput"
                                type="password"
                                className="login__input text-input"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <p>Confirm Password</p>
                            <input
                                id="passwordInputConf"
                                type="password"
                                className="login__input text-input"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            {!passwordsMatch && <p className="error-text">Passwords do not match</p>}
                            <button className="login__button button login__button--reset" onClick={handleLoginClick}>
                                Save Password
                            </button>
                        </div>
                    ) : (
                        <div className="login__reset-saved">
                            <p>Your new password has been saved.</p>
                            <p>
                                Please click <a href="/">here</a> to log in.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
